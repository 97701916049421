import React, { useState, useEffect } from "react";
import { Link, StaticQuery, graphql, useStaticQuery } from "gatsby";
import Layout from "../components/Layout";
import Pattern from "../images/pattern.svg";
import Footer from "../components/Footer";
import Moderniser from "../images/moderniser.svg";
import Securiser from "../images/secure.svg";
import Simplifier from "../images/simplify.svg";
import Suse from "../images/svg/suse_medal_white.svg";
import Salt from "../images/svg/salt_medal_white.svg";
import Logo from "../images/logo_zenops_large.svg";
import Dropdown from "../components/molecules/Dropdown";
import LogoSmall from "../images/logo_zenops_small.svg";
import LogoWhite from "../images/logo_zenops_large_white.svg";

const ExpertiseOptions = [
  {
    title: "Moderniser",
    description:
      "Gagner en agilité avec une infrastructure transformée et évolutive.",
    icon: Moderniser,
    link: "/moderniser",
  },
  {
    title: "Sécuriser",
    description: "Intégrer la sécurité dans une approche by-design.",
    icon: Securiser,
    link: "/securiser",
  },
  {
    title: "Simplifier",
    description:
      "Environnement collaboratif, amélioration de la productivité, la mobilité de vos utilisateurs en toute sérénité.",
    icon: Simplifier,
    link: "/simplifier",
  },
];
const SolutionsOptions = [
  {
    title: "Saltstack",
    description:
      "Gagner en agilité avec une infrastructure transformée et évolutive.",
    icon: Salt,
    link: "/zenops-saltstack",
  },
  {
    title: "Suse",
    description: "Intégrer la sécurité dans une approche by-design.",
    icon: Suse,
    link: "/zenops-suse",
  },
];
const RessourcesOptions = [
  {
    title: "Calendrier",
    description: " Découvrez nos prochains événements !",
    link: "/events",
  },
  {
    title: "Rediffusions",
    description:
      "Visionnez les replays de nos Wébinaires et Tutoriels avec chapitrage intégré.",
    link: "/replays",
  },
];
const Test = ({ isHome }) => {
  const [menu, setMenu] = useState(false);
  const toggleTrueFalse = () => setMenu(!menu);

  const isDark = true;
  return (
    <Layout>
      <div
        className={`relative ${
          isDark ? "bg-gray-900 text-white" : "bg-white"
        }`}>
        <div className="flex  max-w-screen-xl mx-auto justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
          <div>
            <Link to="/">
              <img
                className="h-4 w-auto sm:h-6"
                src={isDark ? LogoWhite : Logo}
                alt="Zenops"
              />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <button
              type="button"
              onClick={toggleTrueFalse}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
          <div className="hidden md:flex-1 md:flex md:items-center md:justify-between md:space-x-12">
            <nav className="flex space-x-10">
              <Dropdown
                label={"Expertises"}
                options={ExpertiseOptions}
                isDark={true}
              />
              <Dropdown label={"Solutions"} options={SolutionsOptions} isDark />
              <Dropdown
                label={"Événements"}
                options={RessourcesOptions}
                isDark
              />

              <Link
                to={"/about"}
                href="#"
                className={`text-base leading-6 font-medium text-gray-500  focus:outline-none transition ease-in-out duration-150 ${
                  isDark ? "hover:text-white" : "hover:text-gray-900"
                }`}>
                À propos
              </Link>
            </nav>
            <div className="flex items-center space-x-8">
              <span className="inline-flex ">
                <a
                  href={isHome ? "#contact" : "/#contact"}
                  className=" inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-100 hover:bg-gray-50 focus:outline-none focus:ring transition duration-150 ease-in-out">
                  Contactez-nous !
                </a>
              </span>
            </div>
          </div>
        </div>

        <div
          className={`absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden
        ${menu ? "block" : "hidden"}`}>
          <div className="rounded-lg shadow-lg">
            <div className="rounded-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5 space-y-6">
                <div className="flex items-center justify-between">
                  <div>
                    <Link to="/">
                      <img
                        className="h-8 w-auto sm:h-10"
                        src={LogoSmall}
                        alt="Logo zenops"
                      />
                    </Link>
                  </div>
                  <div className="-mr-2">
                    <button
                      type="button"
                      onClick={() => setMenu(false)}
                      className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div>
                  <nav className="grid gap-6">
                    <div className="label text-xs font-bold text-primary-900 uppercase border-b">
                      Expertises
                    </div>
                    <Link
                      to={"/moderniser"}
                      className="-m-3 p-3 flex items-center space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150 hover:bg-gray-100">
                      <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md text-white">
                        <img src={Moderniser} />
                      </div>
                      <div className="text-base leading-6 font-medium text-gray-900">
                        Moderniser
                      </div>
                    </Link>
                    <Link
                      to={"/securiser"}
                      className="-m-3 p-3 flex items-center space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150 hover:bg-gray-100">
                      <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md  text-white">
                        <img src={Securiser} />
                      </div>
                      <div className="text-base leading-6 font-medium text-gray-900">
                        Sécuriser
                      </div>
                    </Link>
                    <Link
                      to={"/simplifier"}
                      className="-m-3 p-3 flex items-center space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150 hover:bg-gray-100">
                      <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md  text-white">
                        <img src={Simplifier} />
                      </div>
                      <div className="text-base leading-6 font-medium text-gray-900">
                        Simplifier
                      </div>
                    </Link>
                    <div className="label text-xs font-bold text-primary-900 uppercase border-b">
                      Solutions
                    </div>
                    <Link
                      to={"/zenops-saltstack"}
                      className="-m-3 p-3 flex items-center space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150 hover:bg-gray-100">
                      <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md  text-white">
                        <img src={Salt} />
                      </div>
                      <div className="text-base leading-6 font-medium text-gray-900">
                        SaltStack
                      </div>
                    </Link>
                    <Link
                      to={"/zenops-suse"}
                      className="-m-3 p-3 flex items-center space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150 hover:bg-gray-100">
                      <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md  text-white">
                        <img src={Suse} />
                      </div>
                      <div className="text-base leading-6 font-medium text-gray-900">
                        Suse
                      </div>
                    </Link>
                  </nav>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                <div className="grid grid-cols-2 gap-4">
                  <Link
                    to="/about"
                    className="text-base leading-6 font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150">
                    À propos
                  </Link>
                  <Link
                    to="/events"
                    className="text-base leading-6 font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150">
                    Calendrier
                  </Link>
                  <Link
                    to="/replays"
                    className="text-base leading-6 font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150">
                    Rediffusions
                  </Link>
                </div>
                <div className="space-y-6">
                  <span className=" w-full inline-flex ">
                    <a
                      href={isHome ? "#contact" : "/#contact"}
                      className="w-full justify-center inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-100 hover:bg-gray-50 focus:outline-none focus:ring transition duration-150 ease-in-out">
                      Contactez-nous !
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="relative px-4 sm:px-6 lg:px-8 col-span-3 my-16">
        <div class="text-lg max-w-prose mx-auto my-24 prose-lg prose-indigo">
          <h1>
            <span class="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              Introducing
            </span>
            <span class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              JavaScript for Beginners
            </span>
          </h1>
          <p class="mt-8 text-xl text-gray-500 leading-8">
            Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At
            arcu, sit dui mi, nibh dui, diam eget aliquam. Quisque id at vitae
            feugiat egestas ac. Diam nulla orci at in viverra scelerisque eget.
            Eleifend egestas fringilla sapien.
          </p>
        </div>
      </div>
      <form action="">
      <label for="search" class="sr-only">Search</label>
      <div class="relative text-gray-400 focus-within:text-gray-600">
        <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">

          <svg class="h-5 w-5" >
            <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
          </svg>
        </div>
        <input name="term" autocomplete="off" placeholder="{{ module.placeholder }}" id="search" type="search" class="hs-search-field__input block w-full bg-white py-2 pl-10 pr-3 border-2 border-transparent rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary-900  sm:text-sm shadow-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 p-4  col-span-1 sm:col-span-2" />

        <input name="term" autocomplete="off"  id="search" type="search" class="hs-search-field__input block w-full bg-white py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white focus:border-white sm:text-sm sm:col-span-1" />
</div>
      </form>
      <div class="pointer-events-none absolute inset-y-0 left-0 pl-4 flex items-center">
          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
          </svg>
        </div>
        <input name="term" autocomplete="off" placeholder="{{ module.placeholder }}" id="search" type="search" class="hs-search-field__input block w-full bg-white py-4 pl-12 pr-3 border-2 border-transparent rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary-900  sm:text-sm shadow-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 p-4 md:col-span-2" />


      <div class="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div class="absolute inset-0">
          <div class="bg-white h-1/3 sm:h-2/3"></div>
        </div>
        <div class="relative max-w-7xl mx-auto">
          <div class="text-center">
            <h2 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
              From the blog
            </h2>
            <p class="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa
              libero labore natus atque, ducimus sed.
            </p>
          </div>
          <div class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none col-span-2">
            <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div class="flex-shrink-0">
                <img
                  class="h-48 w-full object-cover"
                  src="https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80"
                  alt=""
                />
              </div>
              <div class="flex-1 bg-white p-6 flex flex-col justify-between">
                <div class="flex-1">
                  <p class="text-sm font-medium text-indigo-600">
                    <a href="#" class="hover:underline">
                      Article
                    </a>
                  </p>
                  <a href="#" class="block mt-2">
                    <p class="text-xl font-semibold text-gray-900">
                      Boost your conversion rate
                    </p>
                    <p class="mt-3 text-base text-gray-500">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Architecto accusantium praesentium eius, ut atque fuga
                      culpa, similique sequi cum eos quis dolorum.
                    </p>
                  </a>
                </div>
                <div class="mt-6 flex items-center">
                  <div class="flex-shrink-0">
                    <a href="#">
                      <span class="sr-only">Roel Aufderehar</span>
                      <img
                        class="h-10 w-10 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                    </a>
                  </div>
                  <div class="ml-3">
                    <p class="text-sm font-medium text-gray-900">
                      <a href="#" class="hover:underline">
                        Roel Aufderehar
                      </a>
                    </p>
                    <div class="flex space-x-1 text-sm text-gray-500">
                      <time datetime="2020-03-16">Mar 16, 2020</time>
                      <span aria-hidden="true">&middot;</span>
                      <span>6 min read</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div class="flex-shrink-0">
                <img
                  class="h-48 w-full object-cover"
                  src="https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80"
                  alt=""
                />
              </div>
              <div class="flex-1 bg-white p-6 flex flex-col justify-between">
                <div class="flex-1">
                  <p class="text-sm font-medium text-indigo-600">
                    <a href="#" class="hover:underline">
                      Video
                    </a>
                  </p>
                  <a href="#" class="block mt-2">
                    <p class="text-xl font-semibold text-gray-900">
                      How to use search engine optimization to drive sales
                    </p>
                    <p class="mt-3 text-base text-gray-500">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Velit facilis asperiores porro quaerat doloribus, eveniet
                      dolore. Adipisci tempora aut inventore optio animi.,
                      tempore temporibus quo laudantium.
                    </p>
                  </a>
                </div>
                <div class="mt-6 flex items-center">
                  <div class="flex-shrink-0">
                    <a href="#">
                      <span class="sr-only">Brenna Goyette</span>
                      <img
                        class="h-10 w-10 rounded-full"
                        src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                    </a>
                  </div>
                  <div class="ml-3">
                    <p class="text-sm font-medium text-gray-900">
                      <a href="#" class="hover:underline">
                        Brenna Goyette
                      </a>
                    </p>
                    <div class="flex space-x-1 text-sm text-gray-500">
                      <time datetime="2020-03-10">Mar 10, 2020</time>
                      <span aria-hidden="true">&middot;</span>
                      <span>4 min read</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div class="flex-shrink-0">
                <img
                  class="h-48 w-full object-cover"
                  src="https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80"
                  alt=""
                />
              </div>
              <div class="flex-1 bg-white p-6 flex flex-col justify-between">
                <div class="flex-1">
                  <p class="text-sm font-medium text-indigo-600">
                    <a href="#" class="hover:underline">
                      Case Study
                    </a>
                  </p>
                  <a href="#" class="block mt-2">
                    <p class="text-xl font-semibold text-gray-900">
                      Improve your customer experience
                    </p>
                    <p class="mt-3 text-base text-gray-500">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Sint harum rerum voluptatem quo recusandae magni placeat
                      saepe molestiae, sed excepturi cumque corporis perferendis
                      hic.
                    </p>
                  </a>
                </div>
                <div class="mt-6 flex items-center">
                  <div class="flex-shrink-0">
                    <a href="#">
                      <span class="sr-only">Daniela Metz</span>
                      <img
                        class="h-10 w-10 rounded-full"
                        src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                    </a>
                  </div>
                  <div class="ml-3">
                    <p class="text-sm font-medium text-gray-900">
                      <a href="#" class="hover:underline">
                        Daniela Metz
                      </a>
                    </p>
                    <div class="flex space-x-1 text-sm text-gray-500">
                      <time datetime="2020-02-12">Feb 12, 2020</time>
                      <span aria-hidden="true">&middot;</span>
                      <span>11 min read</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <header className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
        <div id="hb" className="bg-gray-100 sm:p-10 p-8 h-full"></div>
      </header>
      <Footer />
    </Layout>
  );
};

export default Test;
